import React, { useEffect } from 'react';
import "./product.css";



const API_BASE_URL = process.env.NODE_ENV === 'production'
? ''
: 'https://candcsolutions.co.uk';

export function Articles() {
  const [blogs, setBlogs] = React.useState<any[]>([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`public/articles.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBlogs(data.results)
        
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);
  return (
    <div className="product-section">
      <h2>Articles</h2>
      <h3>Workstation Health</h3>
      <ul>
        {blogs.map((blog, index) => (
          <li key={index}>
            <span>
              <h4>{blog.name}</h4>
              <a href={blog.url} target="_blank" rel="noopener noreferrer">
                Read More
              </a>
            </span>
          </li>
        ))}
      </ul>
      <p></p>
    </div>
  );
}

export default Articles;